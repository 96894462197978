<template>
  <div class="content">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/manage/exam/auto">智能出卷</el-breadcrumb-item>
        <el-breadcrumb-item>查看该规则下试卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="eva-title bg-shadow">
      <el-button :disabled="mulSelection.length === 0" type="danger" @click="batcDel()" size="small">批量删除</el-button>
      <el-input
        size="small"
        style="width: 270px"
        v-model="keyword"
        @keyup.native.enter="searchByName()"
        prefix-icon="el-icon-search"
        placeholder="用户名称查找(回车搜索)"
      />
    </div>
    <div style="height: calc(100% - 93px); margin: 0 10px">
      <el-table :data="tableData" width="100%" height="calc(100% - 42px)" :loading="loading" @selection-change="handleSelec">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column label="用户名称" prop="userName" />
        <el-table-column label="标签" prop="tag">
          <template slot-scope="scope">{{ scope.row.tabs ? scope.row.tabs.map(item => item).join('、') : '/' }}</template>
          <!--          <template slot-scope="scope">{{scope.row.tabs.length > 0 ? scope.row.tabs.map(item => item).join('、') : '/'}}</template>-->
        </el-table-column>
        <el-table-column label="岗位" prop="postName" />
        <el-table-column label="开始时间" prop="startTime">
          <template slot-scope="scope">{{ scope.row.startTime | formDate }}</template>
        </el-table-column>
        <el-table-column label="结束时间" prop="endTime">
          <template slot-scope="scope">{{ scope.row.submitTime | formDate }}</template>
        </el-table-column>
        <el-table-column label="分数" prop="score" align="center">
          <template slot-scope="scope">{{ scope.row.score }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button :title="scope.row.submitTime ? '已提交的试卷不可删除' : ''" type="text" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        style="margin: 10px"
        @on-change="changePage"
        @on-page-size-change="changeSize"
        :page-size-opts="[10, 20, 40, 100]"
        :total="total"
        show-elevator
        show-sizer
        show-total
      />
    </div>
  </div>
</template>

<script>
import paper from '@api/paper'
import exam from '../../../api/exam'
import util from '../../../util/util'

export default {
  name: 'RulePaper',
  data() {
    return {
      loading: false,
      total: 0,
      page: 0,
      size: 10,
      th: document.body.clientHeight - 200,
      tableData: [],
      mulSelection: [],
      keyword: ''
    }
  },
  created() {
    this.initData()
  },
  filters: {
    formDate(data) {
      if (!data) return '/'
      let d = new Date(data)
      return util.dateTime(d)
    }
  },
  methods: {
    searchByName() {
      if (this.keyword.trim() === '') return this.initData()
      let form = {
        postId: this.$route.query['id'],
        pageNum: 0,
        pageSize: this.size,
        userName: this.keyword
      }
      exam.getAutoExam(form).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
        this.keyword = ''
      })
    },
    batcDel() {
      let ids = []
      if (this.mulSelection.length === 0)
        return this.$message({
          message: '请选择需要删除的试卷',
          type: 'warning'
        })
      this.mulSelection.forEach(m => {
        ids.push(m.answerPaperId)
      })
      this.judge(ids)
    },
    handleSelec(row) {
      this.mulSelection = row
    },
    del(row) {
      let ids = []
      ids.push(row.answerPaperId)
      this.judge(ids)
    },
    judge(ids) {
      this.$confirm('确定删除选中的试卷吗', '提示', {
        confirmButtonText: '',
        cancelButtonText: '',
        type: 'warning'
      })
        .then(() => {
          exam.delAutoExam(ids).then(res => {
            if (res.code === 0) {
              this.$message({ message: '成功', type: 'success' })
              this.initData()
            } else {
            }
            this.mulSelection = []
          })
        })
        .catch(() => {
          this.mulSelection = []
        })
    },
    initData() {
      const page = this.page
      const size = this.size
      let sort = ''
      let params = this.$handleParams('rulePaperData')
      this.loading = true
      exam
        .getAutoExams(params.id, sort, page, size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initData()
    },
    changeSize(val) {
      this.size = val
      this.initData()
    }
  }
}
</script>

<style lang="less" scoped>
.eva-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  margin: 10px;
  padding: 0 20px;
}
/deep/ .el-input {
  outline: none;
  border-color: #dedede;
  .el-input__inner {
    border: 0;
    border-bottom: 1px solid #dedede;
    &:hover {
      border-color: #dedede;
    }
  }
  border-bottom: 1px solid #dedede;
}
/deep/ .el-table th,
.el-table tr {
  color: #333;
  background-color: #fafafa !important;
  height: 56px;
}
::v-deep .el-table__row {
  height: 56px;
}
::v-deep .el-table th > .cell {
  font-size: 16px;
  color: #3a4e64;
  font-weight: normal !important;
}
::v-deep .el-table .cell {
  font-size: 14px;
  color: #555555;
}
::v-deep .el-table__expanded-cell {
  font-size: 14px;
  color: #555555;
}
</style>
